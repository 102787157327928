import React, { useState } from "react";
import toggle_light from "../../assets/night.png";
import logo_dark from "../../assets/WhatsApp Image 2024-10-07 at 19.41.32_54c75195.png";
import toggle_dark from "../../assets/day.png";
import { UserType } from "../../dataTypes/myDataTypes";
import MenuIcon from "@mui/icons-material/Menu";
// import { DMemoryType } from "../../dataTypes/myDataTypes";
import { PClock } from "../PClock";
import "./navbar.css";
import { useMediaQuery } from "react-responsive";
import auth from "../../services/auth";
import { json } from "stream/consumers";
interface MyNavBarProps {
  theme: string;
  pageNumber: number;
  user: UserType;
  setPageNumber: (v: number) => void;
  devDataArr: string[];

  setTheme: (n: string) => void;
}
const MyNavBar = ({
  theme,
  setTheme,
  pageNumber,
  setPageNumber,
  user,
  devDataArr,
}: MyNavBarProps) => {
  const toggle_theme = () => {
    theme == "light" ? setTheme("dark") : setTheme("light");
  };

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const selectedStyle = {
    color: "rgb(2, 10, 117)",
    fontSize: "20px",
    fontWeight: "bold",
  };
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className="navbar">
      <img
        src={logo_dark}
        alt=""
        className="logo"
        onClick={() => {
          setPageNumber(0);
        }}
      />
      <div className={`list ${theme}`}>
        {/* Desktop Navbar */}
        {!isMobile && (
          <ul
            style={{
              background: "rgb(65, 169, 255)",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-around",
              cursor: "pointer",
              display: "flex",
              // margin: "10px 10px",
              fontSize: "15px",
              padding: "1px 15px",
              border: "#ced8ff",
              borderRadius: "20px",
            }}
          >
            <li
              className="li1"
              style={pageNumber == 0 ? selectedStyle : {}}
              onClick={() => {
                setPageNumber(0);
              }}
            >
              Data Base
            </li>
            <li
              className="li1"
              style={pageNumber == 3 ? selectedStyle : {}}
              onClick={() => {
                setPageNumber(3);
              }}
            >
              Live Data
            </li>

            {user.authorization == "admin" && (
              <li
                className="li1"
                style={pageNumber == 7 ? selectedStyle : {}}
                onClick={() => {
                  setPageNumber(7);
                }}
              >
                Zone Names
              </li>
            )}
            <li
              className="li1"
              onClick={() => {
                auth.logout();
              }}
            >
              Logout
            </li>
          </ul>
        )}

        {/* ==================================================================================================== */}

        {/* Mobile Navbar */}
        {isMobile && (
          <>
            <MenuIcon className="hamburger" onClick={toggleMobileMenu} />
            {isMobileMenuOpen && (
              <ul
                style={{
                  background: "rgb(65, 169, 255)",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  cursor: "pointer",
                  display: "flex",
                  flexWrap: "wrap",
                  margin: 0,
                  padding: 0,
                  maxWidth: "100%",
                  maxHeight: "100%",
                  fontSize: "15px",
                  // padding: "1px 15px",
                  border: "#ced8ff",
                  borderRadius: "20px",
                }}
              >
                <li
                  className="li1"
                  style={pageNumber == 0 ? selectedStyle : {}}
                  onClick={() => {
                    setPageNumber(0);
                  }}
                >
                  Data Base
                </li>
                <li
                  className="li1"
                  style={pageNumber == 3 ? selectedStyle : {}}
                  onClick={() => {
                    setPageNumber(3);
                  }}
                >
                  Live Data
                </li>

                {user.authorization == "admin" && (
                  <li
                    className="li1"
                    style={pageNumber == 7 ? selectedStyle : {}}
                    onClick={() => {
                      setPageNumber(7);
                    }}
                  >
                    Zone Names
                  </li>
                )}
                <li
                  className="li1"
                  onClick={() => {
                    auth.logout();
                  }}
                >
                  Logout
                </li>
              </ul>
            )}
          </>
        )}

        {/* ============================================================================================= */}
      </div>

      {!isMobile && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <b className="Welcome">{`welcome : ${user.username}`}</b>
          <b className="clock">
            <PClock devDataArr={devDataArr} />
          </b>
        </div>
      )}
      {/* <img
        onClick={() => {
          toggle_theme();
        }}
        src={theme == "light" ? toggle_light : toggle_dark}
        alt=""
        className="toggle-icon"
      /> */}
    </div>
  );
};
export default MyNavBar;
