import _ from "lodash";
import { DBDataType,DBSensorType,HourDataType,MyDateType,MyDispDataType,SensorType } from "../../dataTypes/myDataTypes";
import XLSX from "sheetjs-style";
import { sensors, zoneNames } from "../../configs/Tags";

import FileSaver from "file-saver";

//===========================================================================================================================
export function  getTrimmedTime(str:string):string{
  let hh:string = str.substring(0,2);
  let mm:number = parseInt(str.substring(3,5));
  let trimmedMin:number =   mm - mm%10 ;
  let trimmedMinStr:string =   (trimmedMin<10)? `0${trimmedMin}`:trimmedMin.toString() ;
  return `${hh}:${trimmedMinStr}`
 }
//===========================================================================================================================
export function getSensorValue(
  sensor: SensorType,
  dArr:string[],
): number {
  if(sensor.factorAddress)
    return parseInt(dArr[sensor.address]) * 10 ** parseInt(dArr[sensor.factorAddress]);
  return parseInt(dArr[sensor.address])* 10 ** sensor.factor
}
//===========================================================================================================================
export function getSensorDBAvg(sensor:DBSensorType,dataArr:DBDataType[]):number{
  let sum:number = 0;
  let cnt:number = 0;
  dataArr.forEach(d=>{
    let sd =  d.DbSensors.find(s=>((s.name == sensor.name)&&(s.zone == sensor.zone)))
    if(sd){
      sum+= sd.value;
      cnt+=1;
    }
  })
  if(cnt ==0) return 0
  return sum/cnt;
}
//===========================================================================================================================
export function getSensorDispDataAvg(sensor:DBSensorType,dataArr:MyDispDataType[]):number{
  let sum:number = 0;
  let cnt:number = 0;
  dataArr.forEach(d=>{
    let sd =  d.DbSensors.find(s=>((s.name == sensor.name)&&(s.zone == sensor.zone)))
    if(sd){
      sum+= sd.value;
      cnt+=1;
    }
  })
  if(cnt ==0) return 0
  return sum/cnt;
}
//===========================================================================================================================

export function getListOfDataArray(itemName:string,dataArray:any[]):string[]{
  let returnList:string[] = []
  let uniqArr:any[] = _.uniqBy(dataArray, itemName);
  uniqArr.forEach(d=>returnList.push(d[itemName]));
  return returnList

}
//===========================================================================================================================
export function getZoneSheetData(zone: string, data: MyDispDataType[],selectedSensorNames:string[]): any[] {
  let retArr:any[] = []
  
  let zoneSensors:DBSensorType[] = sensors.filter(s=>(s.zone == zone));  
  let mySensors = zoneSensors.filter(
    (zs) => selectedSensorNames.indexOf(zs.name) >= 0
  );

  data.forEach(dBrecord=>{
    let excelRow:any = {Time:dBrecord.timeDisp};
    mySensors.forEach(dbS=>{
        let ddBs:DBSensorType|undefined = dBrecord.DbSensors.find(ddbS=>ddbS.name == dbS.name);
        if(ddBs)excelRow[dbS.name] =ddBs.value.toFixed(dbS.decimal)
    })

    retArr.push(excelRow)
  })

  
  let lastRow:any = {Time:'Average'};
  mySensors.forEach(dbS=>{
    lastRow[dbS.name] = getSensorDispDataAvg(dbS, data).toFixed(dbS.decimal)
  })
  retArr.push(lastRow)
  


  return retArr;
}
//===========================================================================================================================
export function sendToExcel(dispData: MyDispDataType[],selectedZone:string,selectedSensor:string) {
    let selectedZoneNames:string[] = (selectedZone=='All Zones')?zoneNames:[selectedZone];
    let selectedSensorNames:string[] = (selectedSensor=='All Sensors')? getListOfDataArray("name", sensors):[selectedSensor];
    const workbook = XLSX.utils.book_new();
    zoneNames.forEach(zn=>{
      let worksheet = XLSX.utils.json_to_sheet(getZoneSheetData(zn, dispData,selectedSensorNames));
      XLSX.utils.book_append_sheet(workbook, worksheet, zn);
    }) 
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    FileSaver.saveAs(blob,`data`+ ".xlsx");
}
//===========================================================================================================================
export function getDateStr(d:MyDateType,showHour:boolean):string{
  if(showHour)
      return `${d.D.toString().padStart(2,'0')}/${d.M.toString().padStart(2,'0')}/${d.Y.toString()} (${d.H.toString().padStart(2,'0')})`;
  return `${d.D.toString().padStart(2,'0')}/${d.M.toString().padStart(2,'0')}/${d.Y.toString()}`;
}
//========================================================================================================================
export function DaysOfMonth(m:number,y:number):number{
  
  if(m==2){
      if ((y%4) ==0) return 29;
      return 28;
  } 
  if((m ==1)||(m ==3)||(m ==5)||(m ==7)||(m ==8)||(m ==10)||(m ==12) )return 31;
  return 30;
}
//========================================================================================================================
export function getPrevDate(d:MyDateType,decDays:number):MyDateType{
  let nDay = d.D;
  let nMonth = d.M;
  let nYear = d.Y;
  
  if(nDay>=decDays){
      nDay = nDay-decDays;
  }
  else{
      if(d.M>1){
          nMonth = nMonth-1;
          nDay = DaysOfMonth(nMonth,nYear) -  (decDays-d.D-1);
      }
      else{
          nMonth = 12;
          nYear = nYear-1;
          nDay = 31-decDays;
      } 
  }

  return {D:nDay,M:nMonth,Y:nYear,H:0,m:0};
}
//========================================================================================================================
export function MyCompareDates(d1:MyDateType,d2:MyDateType,cmp:string):boolean{
  if(cmp=='>='){
      if(d1.Y > d2.Y) return true;
      if((d1.M > d2.M)&&(d1.Y==d2.Y)) return true;
      if((d1.D > d2.D)&&(d1.M==d2.M)&&(d1.Y==d2.Y) ) return true;
      if((d1.H >= d2.H)&&(d1.D==d2.D)&&(d1.M==d2.M)&&(d1.Y==d2.Y) ) return true;
  }
  else if(cmp=='<='){
      if(d1.Y < d2.Y) return true;
      if((d1.M < d2.M)&&(d1.Y==d2.Y)) return true;
      if((d1.D < d2.D)&&(d1.M==d2.M)&&(d1.Y==d2.Y) ) return true;
      if((d1.H <= d2.H)&&(d1.D==d2.D)&&(d1.M==d2.M)&&(d1.Y==d2.Y) ) return true;
  }
  else if(cmp=='=='){
      return (d1.Y == d2.Y)&&(d1.M == d2.M)&&(d1.D == d2.D);
  }
  return false
}

//========================================================================================================================
// export function getTreeData(data:DBDataType[]):YearDataType[]{
//     let yearsDrec:DBDataType[] = _.uniqBy(data,'year');
//     let years:YearDataType[] = []; 
//     yearsDrec.forEach(ydr=>{
//       let year:YearDataType ={
//         year: ydr.MyDate.Y,
//         months: []
//       } 
//       let monthsDrec:DBDataType[] = _.uniqBy(data.filter(sd=>sd.year == ydr.year),'month');
//       monthsDrec.forEach(mdr=>{
//         let month:MonthDataType = {year: year.year,month:mdr.month,days: []}
        
//         let daysDrec:DBDataType[] = _.uniqBy(data.filter(sd=>((sd.year == ydr.year)&&(sd.month == month.month)) ),'day');
        
        
        
//         daysDrec.forEach(ddr=>{
//           let day:DayDataType = {
//             year: year.year,
//             month: month.month,
//             day: ddr.day,
//             hours: []
//           }
          
//           month.days.push({...day});
          
          
//           let hoursDRec:DBDataType[] = _.uniqBy(data.filter(sd=>((sd.year == day.year)&&(sd.month == day.month)&&(sd.day == day.day) ) ),'hour');
          
//           hoursDRec.forEach(hdr=>{
//             let hr:HourDataType ={
//               year: hdr.year,
//               month: hdr.month,
//               day: hdr.day,
//               hour: hdr.hour,
//               data: [...data.filter(sd=>((sd.year == hdr.year)&&(sd.month == hdr.month)&&(sd.day == hdr.day)&&(sd.hour==hdr.hour) ) )]
//             }
//             day.hours.push({...hr});
//           })
          
//         })
//         year.months.push({...month});
//       })
//       years.push({...year});
//     })
// return years;
// }
//========================================================================================================================
export function getTimeOfDisp(detailsTimeDisp:string,dataRecord:DBDataType,allRecords:DBDataType[]): string {
  if (_.uniqBy(allRecords,'day').length > 1){
    if (detailsTimeDisp == "hour") 
      return (`${getDateStr(dataRecord.MyDate,true)}`)  
    else 
      return (`${getDateStr(dataRecord.MyDate,false)} (${dataRecord.hourMinute}) `)  

  }
  if (detailsTimeDisp == "hour") return dataRecord.MyDate.H.toString();
  return dataRecord.hourMinute;
}
//========================================================================================================================
// export function getAverageDisp(sensor: DBSensorType,detailsTimeDisp:string,myDetailedRecords:DBDataType[]): string {
//   if (detailsTimeDisp == "hour") {
//     let myAvg = getSensorAverage(sensor, myDetailedRecords);
//     return myAvg.toFixed(sensor.decimal);
//   }
//   return sensor.value.toFixed(sensor.decimal);
// }
//========================================================================================================================