import { useEffect, useState } from "react";
import {
  DBDataType,
  DBSensorType,
  HourDataType,
  MyDispDataType,
  SensorType,
} from "../../dataTypes/myDataTypes";

import _ from "lodash";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";

import { sensors } from "../../configs/Tags";
import "../../index.css";
import { LineChart, LineSeriesType } from "@mui/x-charts";
import { getSensorDispDataAvg } from "./dataUtils";

//========================================================================================================================
interface MyDataRowProps {
  zoneName: string;
  selectedSensorNames: string[];
  dispData: MyDispDataType;
}
//-------------------------------------------------------------------------------------------------------------------------
export function MyDataRow({
  zoneName,
  selectedSensorNames,
  dispData,
}: MyDataRowProps) {
  let zoneSensors: DBSensorType[] = dispData.DbSensors.filter(
    (dbs) => dbs.zone == zoneName
  );
  let selectedSensors: DBSensorType[] = zoneSensors.filter(
    (s) => selectedSensorNames.indexOf(s.name) != -1
  );
  return (
    <TableRow component="th" scope="row">
      <TableCell align="center">{`${dispData.timeDisp}`}</TableCell>
      {selectedSensors.map((s) => (
        <TableCell key={`${s.zone}-${s.name}`} align="center">
          {s.value.toFixed(s.decimal)}
        </TableCell>
      ))}
    </TableRow>
  );
}

//========================================================================================================================
interface MyZoneTableProps {
  dispData: MyDispDataType[];
  zoneName: string;
  selectedSensorNames: string[];
}
//-------------------------------------------------------------------------------------------------------------------------
export function MyZoneTable({
  zoneName,
  dispData,
  selectedSensorNames,
}: MyZoneTableProps) {
  let zoneSensors = sensors.filter((s) => s.zone == zoneName);
  let mySensors = zoneSensors.filter(
    (zs) => selectedSensorNames.indexOf(zs.name) >= 0
  );
  if (mySensors.length == 0) return <></>;

  let cellWidth = 75;
  let myWidth = (mySensors.length + 1) * cellWidth;
  return (
    <TableContainer
      sx={{ minWidth: myWidth, maxWidth: "100%" }}
      component={Paper}
    >
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell rowSpan={2}>Time</TableCell>
            <TableCell colSpan={mySensors.length} align="center">
              {zoneName}
            </TableCell>
          </TableRow>

          <TableRow>
            {mySensors.map((sn) => (
              <TableCell align="center" key={`${sn.zone}-${sn.name}`}>
                {sn.name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {_.uniqBy(dispData, "timeDisp").map((d) => (
            <MyDataRow
              key={`${d.timeDisp}`}
              selectedSensorNames={selectedSensorNames}
              zoneName={zoneName}
              dispData={d}
            />
          ))}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TableCell align="center">
              <b>Average</b>
            </TableCell>
            {mySensors.map((s) => (
              <TableCell align="center" key={`${s.zone}-${s.name}`}>
                <b>{getSensorDispDataAvg(s, dispData).toFixed(s.decimal)}</b>
              </TableCell>
            ))}
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
//========================================================================================================================
interface MyGraphProps {
  dispData: MyDispDataType[];
  selectedZoneNames: string[];
  selectedSensorNames: string[];
}
//-------------------------------------------------------------------------------------------------------------------------
export function MyGraph({
  dispData,
  selectedZoneNames,
  selectedSensorNames,
}: MyGraphProps) {
  let mySensors = sensors.filter(
    (s) =>
      selectedZoneNames.indexOf(s.zone) >= 0 &&
      selectedSensorNames.indexOf(s.name) >= 0
  );

  let xLabels: string[] = [];
  let mySeries: LineSeriesType[] = [];
  let myYAxis: any[] = [];

  mySensors.forEach((sensor) => {
    mySeries = [
      ...mySeries,
      {
        type: "line",
        label: `${sensor.zone}-${sensor.name}`,
        data: [],
        yAxisId: sensor.name,
      },
    ];
    myYAxis = [
      ...myYAxis,
      {
        id: sensor.name,
        min: sensor.minVal,
        max: sensor.maxVal,
        scaleType: "linear",
      },
    ];
  });

  dispData.forEach((record) => {
    xLabels.push(record.timeDisp);
    record.DbSensors.forEach((dbSensor) => {
      let series: LineSeriesType | undefined = mySeries.find(
        (s) => s.label == `${dbSensor.zone}-${dbSensor.name}`
      );
      if (series && series.data) {
        series.data.push(dbSensor.value);
      }
    });
  });

  return (
    <LineChart
      xAxis={[{ scaleType: "point", data: xLabels }]}
      yAxis={myYAxis}
      series={mySeries}
      leftAxis={myYAxis[0]}
      width={1400}
      height={800}
    />
  );
}
//========================================================================================================================
interface MyDataDispProps {
  dispData: MyDispDataType[];
  selectedZoneNames: string[];
  selectedSensorNames: string[];
  selectedTypeDisp: string;
}
//-------------------------------------------------------------------------------------------------------------------------
export function MyDataDisp({
  dispData,
  selectedZoneNames,
  selectedSensorNames,
  selectedTypeDisp,
}: MyDataDispProps) {
  return (
    <div
      style={{
        margin: 5,
        maxHeight: "1070px",
        overflow: "scroll",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: 2,
        }}
      >
        {selectedTypeDisp == "table" &&
          selectedZoneNames.map((zn) => (
            <MyZoneTable
              key={zn}
              zoneName={zn}
              selectedSensorNames={selectedSensorNames}
              dispData={dispData}
            />
          ))}

        {selectedTypeDisp == "graph" && (
          <MyGraph
            dispData={dispData}
            selectedSensorNames={selectedSensorNames}
            selectedZoneNames={selectedZoneNames}
          />
        )}
      </div>
    </div>
  );
}
//========================================================================================================================
